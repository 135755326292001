import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
import PoseStaking from 'views/PoseStaking'

const PoseStakingPage = () => {
  return <PoseStaking />
}

PoseStakingPage.chains = [SUPPORT_ONLY_BSC[0]]

export default PoseStakingPage
